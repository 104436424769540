// @ts-ignore
import UAParser from "ua-parser-js";
import { v4 as uuidv4 } from "uuid";
import { CaptureWebVitals } from "@mmt/web-vitals-logger";

import {
  CARD_CTA_FETCH_API_PATH,
  CARD_TYPE,
  COOKIES,
  GOIBIBO_FONT_FAMILY,
  DEVICE_MODEL_TYPE,
  HOST,
  LISTING_API_PATH,
  MMT_FONT_FAMILY,
  SOURCE,
  TRIPMONEY_FONT_FAMILY,
  DEVICE_OS,
  MMT_URL,
  PARTNER,
  UTM_PARAMS_KEY,
  UTM_SOURCE_VALUE,
  SUB_DOMAIN,
  MMT_SUB_DOMAIN,
  REDIRECTION_FLOW,
  POKUS_EXP_VERSION,
  MakeMyTrip,
  DESKTOP,
  MOBILE,
  GET_CONFIG_API_PATH,
  DEVICE_ID_BRIDGE_STATE,
  PLATFORM,
  CROSS_SELL_API_PATH,
  DATE_FORMATS,
} from "../constants";
import { defaultForexCardsData } from "../constants/defaultCardData.ts";
import { ForexProducts, ProductData, ProductType } from "../types";
import { setCookie, getCookie, removeCookie } from "./cookieUtils";
import {
  DeviceType,
  redirectToTMLandingPage,
} from "../hooks/useRedirectionUrls";
import {
  getMobileOperatingSystem,
  redirectInterceptHref,
} from "../static/utils";
import { BridgeMethods } from "./bridges";
import { format } from "date-fns";

export const getDevice = (isDesktop?: boolean) => {
  if (isDesktop != null) {
    return isDesktop ? "lg" : "sm";
  }
  return null;
};

// Function to get a value from session storage

export function isOnServer() {
  if (typeof window === "undefined") {
    return true;
  }
  return false;
}

export const isApiInvoked = (
  isSuccess: boolean,
  isLoading: boolean,
  isError: boolean
) => {
  if (!(isSuccess || isLoading || isError)) {
    return false;
  }
  return true;
};

export const getElementUniqueIds = (
  page: string,
  section: string,
  element = ""
) => {
  return [page, section, element].filter(Boolean).join("_");
};
export const isApiRequestCompleted = (
  isSuccess: boolean | null | undefined,
  isError: boolean | null | undefined
) => isSuccess || isError;

export function getContextUrl(partner?: string | null) {
  return LISTING_API_PATH.replace(/\{partner\}/g, partner ?? "");
}

export function getConfigUrl(partner?: string | null) {
  return GET_CONFIG_API_PATH.replace(/\{partner\}/g, partner ?? "");
}

export function getCrossSellApiUrl(partner?: string | null) {
  return CROSS_SELL_API_PATH.replace(/\{partner\}/g, partner ?? "");
}

export function getCardCtaSignalStateUrl({
  partner,
  card_type,
}: {
  partner?: string | null;
  card_type: ProductType;
}) {
  return CARD_CTA_FETCH_API_PATH.replace(/\{partner\}/g, partner ?? "").replace(
    /\{card_type\}/g,
    card_type ?? ""
  );
}

export function getHostName() {
  if (isOnServer()) {
    return null;
  }
  return window.location.hostname;
}

export function getSourceName() {
  if (isOnServer()) {
    return null;
  }
  const hostName = window.location.hostname;
  const source =
    hostName === SOURCE.LOCALHOST
      ? hostName
      : window.location.hostname?.split(".")?.[1];
  if (![...Object.values(SOURCE)].includes(source)) {
    throw new Error(`Wrong source name ${source} [getSourceName]`);
  }
  return source;
}

export const getCamelCaseStr = (text: string) => {
  const ans = text.toLowerCase();
  return ans
    .split(" ")
    .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
};

export const persistSpecificParamInCookie = (
  specificParamKey: string,
  specificParamValue: string,
  options: any = { path: "/" }
) => {
  setCookie(specificParamKey, specificParamValue, true, options);
};

export function dispatchEmailLoginCustomEvent(showModal: boolean) {
  document.dispatchEvent(
    new CustomEvent("emailLoginError", { detail: { showModal } })
  );
}

export function shouldOpenMMTLogin() {
  return (
    getHostName() === HOST.MAKEMYTRIP && !getCookie(COOKIES.MMT_AUTH_KEY, true)
  );
}

export const isPartnerLoggedIn = () => {
  if (
    getCookie(COOKIES.GI_AUTH_KEY, true) ||
    getCookie(COOKIES.MMT_AUTH_KEY, true)
  ) {
    return true;
  }
  return false;
};

export function setDefaultForexCardsData() {
  const forexCards = [];
  for (const [key, value] of Object.entries(defaultForexCardsData)) {
    if (key !== CARD_TYPE.INR_CARD) {
      forexCards.push({
        [key]: value,
      });
    }
  }
  return forexCards;
}

export function getHostFontFamily(hostname: string | null) {
  switch (hostname) {
    case HOST.MAKEMYTRIP:
      return MMT_FONT_FAMILY;
    case HOST.GOIBIBO:
      return GOIBIBO_FONT_FAMILY;
    default:
      return TRIPMONEY_FONT_FAMILY;
  }
}
export const getDeviceType = (): DeviceType | null => {
  if (isOnServer()) return null;
  // @ts-ignore
  if (window.mmt_android_bridge || window.app_bridge) {
    return DEVICE_MODEL_TYPE.APP;
  }
  return DEVICE_MODEL_TYPE.WEB;
};

export function getPlatform() {
  if (isOnServer()) return null;

  const deviceType = getDeviceType();
  if (deviceType === DEVICE_MODEL_TYPE.WEB) {
    if (isMobile()) {
      return PLATFORM.PWA;
    }
    return PLATFORM.DESKTOP;
  }
  return PLATFORM.APP;
}

export const isMobile = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a ? a.substr(0, 4) : null
      )
    )
      check = true;
  })(
    // @ts-ignore
    !isOnServer()
      ? //@ts-ignore
        navigator.userAgent || navigator.vendor || window?.opera
      : null
  );
  return check;
};

type CloseWebViewType = {
  partner?: string | null;
};
export const closeWebview = ({ partner }: CloseWebViewType = {}) => {
  if (getHostName() === HOST.MAKEMYTRIP) {
    redirectInterceptHref(MMT_URL);
    return;
  }

  if (
    partner === PARTNER.GI &&
    getDeviceType() === DEVICE_MODEL_TYPE.WEB &&
    getCookie(UTM_PARAMS_KEY.UTM_SOURCE, false, { path: "/" }) ===
      UTM_SOURCE_VALUE.BOTTOM_BAR
  ) {
    redirectToTMLandingPage(PARTNER.GI);
    return;
  }

  const mobileos = getMobileOperatingSystem();
  //@ts-ignore
  if (window?.app_bridge) {
    if (mobileos.toLowerCase() === "android") {
      //@ts-ignore
      window?.app_bridge?.closeAndRefreshWebView();
    } else if (mobileos.toLowerCase() === "ios") {
      // @ts-ignore
      window?.webkit.messageHandlers?.closeAndRefreshWebView?.postMessage(
        JSON.stringify(true)
      );
    }
    return;
    //@ts-ignore
  } else if (window?.mmt_android_bridge?.closeWebView) {
    //@ts-ignore
    window?.mmt_android_bridge?.closeWebView(false);
    return;
  }
  window.history?.back();
};

/**
 * Returns the partner based on the host name.
 * @param partner - The partner name.
 * @returns The partner name based on the host name.
 */
export const getPartner = (partner?: string | null) => {
  const host = getHostName();

  if (host?.includes?.(SOURCE.MAKEMYTRIP)) {
    return PARTNER.MMT;
  } else if (host?.includes?.(SOURCE.GOIBIBO)) {
    return PARTNER.GI;
  } else {
    return partner;
  }
};

export const getCookieExpiryTime = (days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  return date.toUTCString();
};

export const getUserDeviceOS: any = () => {
  if (isOnServer()) {
    return null;
  }
  //@ts-ignore
  const userAgent = navigator?.userAgent || navigator?.vendor || window?.opera;
  if (/android/i?.test(userAgent)) {
    return DEVICE_OS.ANDROID;
  }
  //@ts-ignore
  if (/iPad|iPhone|iPod/?.test(userAgent) && !window?.MSStream) {
    return DEVICE_OS.IOS;
  }
  return DEVICE_OS.UNKNOWN;
};

export const isPartnerHost = (partner: string) => {
  const hostname = getHostName() || "";
  return hostname.includes(partner);
};

export function getUTMParamsFromQuery(): Record<string, string> {
  const queryParams = new URLSearchParams(window.location.search);
  const utmParams: Record<string, string> = {};

  queryParams.forEach((value, key) => {
    if (key.startsWith("utm_")) {
      utmParams[key] = value;
    }
  });

  return utmParams;
}

export function setUtmParamsInCookie(utmParams: Record<string, string>) {
  for (const name in utmParams) {
    const value = utmParams[name];
    [...Object.values(SUB_DOMAIN)].forEach((domain) => {
      setCookie(name, value, false, { domain, path: "/" });
    });
  }
}

export function deleteUtmParamsFromCookie() {
  deleteCookiesWithPrefix("utm_", Object.values(SUB_DOMAIN));
}

export function deleteCookiesWithPrefix(
  prefix: string,
  domainList: Array<string>
) {
  // Loop through each domain
  domainList.forEach((domain: string) => {
    // Get all cookies for the current domain
    const cookies = document.cookie.split("; ");

    cookies.forEach((cookie) => {
      // Split cookie into key and value
      const [key] = cookie.split("=");
      if (key.startsWith(prefix)) {
        removeCookie(key, { domain });
      }
    });
  });
}

export const getPartnerFromUrl = () => {
  if (isOnServer()) {
    return null;
  }
  const path = window.location.pathname;
  const partner = path.split("/")[2] || null;
  return partner;
};

const getDeviceIdWeb = (partner: string | null | undefined) => {
  switch (partner) {
    case PARTNER.MMT:
      return getCookie(COOKIES.MMT_DEVICE_ID, true) || null;
    case PARTNER.GI:
      return getCookie(COOKIES.GI_DEVICE_ID, true) || null;
    default:
      return null;
  }
};

const getDeviceIdApp = () => {
  if (isOnServer()) {
    return null;
  }
  return window?.app_bridge?.deviceId?.() || null;
};

export const isApp = () => {
  if (isOnServer()) {
    return false;
  }
  return window?.app_bridge || window?.webkit?.messageHandlers?.login
    ? true
    : false;
};

export const getDeviceIdStatus = () => {
  if (isOnServer()) {
    return null;
  }
  if (isApp()) {
    if (window?.app_bridge?.deviceId) {
      if (window?.app_bridge?.deviceId?.()) {
        return DEVICE_ID_BRIDGE_STATE.AVAILABLE;
      } else {
        return DEVICE_ID_BRIDGE_STATE.NON_FUNCTIONAL;
      }
    } else {
      return DEVICE_ID_BRIDGE_STATE.NOT_AVAILABLE;
    }
  }
  return null;
};

export const getDeviceId = (partner: string | null | undefined) => {
  const deviceType = getDeviceType();
  if (deviceType === DEVICE_MODEL_TYPE.WEB) {
    return getDeviceIdWeb(partner);
  } else if (deviceType === DEVICE_MODEL_TYPE.APP) {
    return getDeviceIdApp();
  }
  return null;
};

export const setMmtRedirectionCookie = () => {
  if (getHostName() === HOST.MAKEMYTRIP)
    setCookie(COOKIES.MMT_FOREX_COOKIE, "true", true, {
      domain: MMT_SUB_DOMAIN,
      path: "/",
    });
};

export const isValidJSON = (value: any) => {
  if (typeof value !== "string") {
    return false;
  }
  try {
    return JSON.parse(value);
  } catch (e) {
    return false;
  }
};

export function getCardValue(
  products: ForexProducts | undefined,
  cardType: ProductType
): ProductData | null {
  if (!products) {
    return null;
  }
  for (const product of products) {
    for (const [card, cardValue] of Object.entries<ProductData>(product)) {
      if (card === cardType) {
        return cardValue;
      }
    }
  }
  return null;
}

export function getPokusExpVersion({
  partner,
  currencyCtaRedirect,
}: {
  partner: string | null | undefined;
  currencyCtaRedirect?: number;
}) {
  if (partner === PARTNER.MMT) {
    return currencyCtaRedirect === REDIRECTION_FLOW.OLD
      ? POKUS_EXP_VERSION.MMT.OLD
      : POKUS_EXP_VERSION.MMT.NEW;
  } else {
    return currencyCtaRedirect === REDIRECTION_FLOW.OLD
      ? POKUS_EXP_VERSION.GI.OLD
      : POKUS_EXP_VERSION.GI.NEW;
  }
}

export const getDeviceDetailsObject = (
  ua: any,
  partner: string | null | undefined
) => {
  const parser = new UAParser().setUA(ua).getResult();
  let app_version = BridgeMethods.getAppVersion();
  app_version = `${getPartner() === MakeMyTrip ? "MMT" : "GI"}_${app_version}`;
  return {
    id: getDeviceId(partner),
    os: parser && parser.os && parser.os.name ? parser.os.name : "",
    type: !isMobile()
      ? DESKTOP
      : parser && parser.device && parser.device.type
      ? parser.device.type
      : MOBILE,
    os_version:
      parser && parser.os && parser.os.version ? parser.os.version : "",
    app_version: app_version || null,
    user_agent: ua,
    model: getDeviceType() || null,
    connection_type: getDeviceIdStatus(),
    platform: getPlatform() || "",
  };
};

export function disableEncrytionInWeb() {
  return (
    getDeviceType() === DEVICE_MODEL_TYPE.WEB &&
    [HOST.MAKEMYTRIP, HOST.TRIPMONEY_MAKEMYTRIP].includes(getHostName() || "")
  );
}

// for gdpr options
export function getUtilOptions(options: any) {
  return disableEncrytionInWeb() ? { ...options, isPiiData: false } : options;
}

export function removeNullableField(obj: any) {
  let result: any = null;
  for (const [key, value] of Object.entries(obj)) {
    if (value != null) {
      if (!result) result = {};
      result[key] = value;
    }
  }
  return result;
}
export function removeEmptyKeys(obj: any) {
  const result: any = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      result[key] = value;
    }
  }
  return result;
}

export function getPageName(): string {
  if (isOnServer()) return "";

  const pathname = window.location.pathname;

  function findValidSegment(segments: string[]): string {
    if (segments.length === 0) {
      return "";
    }

    const lastSegment = segments[segments.length - 1];

    if (/^[a-zA-Z]+$/.test(lastSegment)) {
      return lastSegment;
    } else {
      // If the last segment contains any alphanumeric character,
      // remove it and continue recursively
      segments.pop();
      return findValidSegment(segments);
    }
  }

  try {
    const pathSegments = pathname
      .split("/")
      .filter((segment: string) => segment !== "");

    return findValidSegment(pathSegments);
  } catch (e: any) {
    console.error(e?.message);
  }

  return window.location.pathname;
}

export function generateRequestId() {
  return uuidv4();
}

export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const OFFER_TYPES = {
  OFFER: "offer",
  CROSS_SELL_HOTEL: "cross_sell_hotel",
  SALE_COUPONS: "sale_coupons",
  CROSS_SELL_FLIGHT: "cross_sell_flight",
};

export function convertEpochToDate(epoch: number) {
  const date = new Date(epoch);
  const options: any = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate.replace(/(\w+) (\d+), (\d+)/, "$2 $1'$3");
}

export function getJourneyIdFromCookie(partner: string | null | undefined) {
  if (partner === PARTNER.MMT) {
    return getCookie(COOKIES.PDT_JOURNEY_ID, true, {
      domain: `${SUB_DOMAIN.MAKEMYTRIP}`,
      path: "/",
    });
  } else if (partner === PARTNER.GI) {
    return getCookie(COOKIES.PDT_JOURNEY_ID, true, {
      domain: SUB_DOMAIN.GOIBIBO,
      path: "/",
    });
  }
  return null;
}

export function setJourneyIdInCookie({
  partner,
  journeyId,
}: {
  partner: string | null | undefined;
  journeyId: string | undefined;
}) {
  const journeyIdCookie = getJourneyIdFromCookie(partner);
  if (!journeyIdCookie && journeyId) {
    // Get the current date and time
    const currentDate = new Date();

    // Set the expiration time to 1 hour from now
    const expirationTime = new Date(currentDate.getTime() + 60 * 60 * 1000); // 60 minutes * 60 seconds * 1000 milliseconds

    // Convert the expiration time to the HTTP Date format
    const expires = expirationTime.toUTCString();
    removeCookie(COOKIES.PDT_JOURNEY_ID);
    if (partner === PARTNER.MMT) {
      setCookie(COOKIES.PDT_JOURNEY_ID, journeyId, true, {
        domain: SUB_DOMAIN.MAKEMYTRIP,
        path: "/",
        expiresVal: expires,
      });
    } else if (partner === PARTNER.GI) {
      setCookie(COOKIES.PDT_JOURNEY_ID, journeyId, true, {
        domain: SUB_DOMAIN.GOIBIBO,
        path: "/",
        expiresVal: expires,
      });
    }
  }
}

export function getPageUrl() {
  if (isOnServer()) {
    return null;
  }
  return window.location.href;
}

export const formatDate = (date: Date | string, dateFormat: DATE_FORMATS) => {
  if (date) {
    try {
      date = new Date(date);
      return format(date, dateFormat);
    } catch (error) {
      console.log(error);
      console.log("Error in formatting given date", `${date} | ${dateFormat}`);
    }
  }
  return "";
};

export const getQueryParamsFromUrl = (url?: string) => {
  if (!url) {
    url = window.location.search;
  }
  const urlSearchParams = new URLSearchParams(url);
  const queryParams: Record<string, string> = {};
  urlSearchParams.forEach((value, key) => {
    queryParams[key] = value;
  });
  return queryParams;
};

export const toLocaleStringINRFormat = (
  currency: number | string,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2
) => {
  if (currency) {
    return Number(currency).toLocaleString("en-IN", {
      minimumFractionDigits:
        minimumFractionDigits > maximumFractionDigits
          ? 2
          : minimumFractionDigits,
      maximumFractionDigits:
        maximumFractionDigits == 0 && maximumFractionDigits == 0
          ? 0
          : maximumFractionDigits > 2
          ? maximumFractionDigits
          : 2,
    });
  }
  return "0";
};

export const addWebVitalsScript = ({
  prefix = "FOREX_VITALS",
  sampleSize = 100,
}: {
  prefix?: string;
  sampleSize?: number;
} = {}) => {
  const Event = new CaptureWebVitals(
    prefix,
    sampleSize,
    65871,
    "mmt_commons_b2c_pdt_client_metric_logging"
  );
  Event.init(true, true, true);
};

export * from "./pdtutils/pdtutils";
export * from "./pdtutils/pdtLogger";
