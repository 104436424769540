import { useState, useEffect } from "react";

import {
  CARD_TYPE,
  COOKIES,
  DEFAULT_FLOW,
  GI_SUB_DOMAIN,
  HOST,
  MMT_SUB_DOMAIN,
  MakeMyTrip,
  PARTNER,
  SESSION_STORAGE_KEYS,
  SOURCE,
  VERSIONS,
} from "../../constants";
import {
  closeWebview,
  getDeviceType,
  getHostName,
  getSourceName,
  persistSpecificParamInCookie,
  setMmtRedirectionCookie,
} from "../../utils";
import { removeSessionStorageItem } from "../../utils/clientStorageUtils";
import {
  landingCardShowcaseDataProps,
  landingForexOfferingCardDataProps,
} from "../../static/mockData";

import { productTilesRedirections } from "../../utils/redirectionUtils";

import {
  completeOrderRedirectionsV2,
  reloadTripRedirection,
  exploreForexCurrencyRedirectionV2,
  productTilesRedirectionsV2,
  quickLoadMoneyRedirectionsV2,
  redirectKnowMoreCardLinksV2,
  redirectUploadDocsV2,
  trackCardRedirectionsV2,
  viewDashboardRedirectionsV2,
  viewOrdersRedirectionsV2,
} from "../../utils/redirectionUtilsV2";

import { useGlobalConfig } from "../useGlobalConfig";
import { useGlobalSelector } from "../useGlobalSelector";
import { ListingReducerType } from "../../store/forexListingReducer";
import { redirectInterceptHref } from "../../static/utils";
import {
  redirectDropOffV3,
  redirectUploadDocsV3,
  startOrderRedirectionsV3,
  trackCardRedirectionsV3,
} from "../../utils/redirectionUtilsV3";

export type DeviceType = "Web" | "App";

const useRedirectionUrls = () => {
  const { partner: tmAppPartner } = useGlobalConfig();
  const [{ partner, host }, setPartnerHost] = useState<{
    partner: string | null | undefined;
    host: string | null | undefined;
  }>({ partner: null, host: "" });

  const [device, setDevice] = useState<DeviceType | null>(null);

  useEffect(() => {
    const appType = getDeviceType();
    setDevice(appType);
  }, []);

  const { version, feature_flag } = useGlobalSelector(
    (state: ListingReducerType) => state || {}
  );

  useEffect(() => {
    if (tmAppPartner) {
      setPartnerHost(getHostPartnerInfoForRedirections(tmAppPartner));
    }
  }, [tmAppPartner]);

  const isHostMakeMyTrip = getHostName() === HOST.MAKEMYTRIP;
  const initString = partner === PARTNER.MMT ? "initOTU" : "initOTUGI";
  const basePartnerUrl = `https://lending.${host}.com/ext/api/v1/partners/${partner}/${initString}`;
  const otuForexPartnerUrl = `${basePartnerUrl}?product=forex${
    isHostMakeMyTrip ? "&mmt_forex=true" : ""
  }`;

  const tmBaseUrl = `${
    getHostName() === HOST.MAKEMYTRIP
      ? "https://" + HOST.TRIPMONEY_MAKEMYTRIP
      : ""
  }/partners/${partner}/forex`;
  const mwebtmBaseUrl = `${
    getHostName() === HOST.MAKEMYTRIP
      ? "https://" + HOST.TRIPMONEY_MAKEMYTRIP
      : ""
  }/mweb/partners/${partner}/forex/bmf`;

  return {
    productTilesRedirections: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const versionToHandlerMap = {
        [VERSIONS.V3]: productTilesRedirectionsV2,
        [VERSIONS.V2]: productTilesRedirectionsV2,
      };
      const productVersion = item?.version || DEFAULT_FLOW;
      versionToHandlerMap[productVersion](item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        device,
        mwebtmBaseUrl,
      });
    },
    startOrderRedirections: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      const versionToHandlerMap = {
        [VERSIONS.V3]: startOrderRedirectionsV3,
        // TODO - should use v2?
        [VERSIONS.V2]: productTilesRedirections,
      };
      versionToHandlerMap[productVersion](item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    redirectUploadDocs: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      item.feature_flag = feature_flag || {};
      const versionToHandlerMap = {
        [VERSIONS.V3]: redirectUploadDocsV3,
        [VERSIONS.V2]: redirectUploadDocsV2,
      };
      versionToHandlerMap[productVersion](item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    redirectKnowMoreCardLinks: (item: landingForexOfferingCardDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      const versionToHandlerMap = {
        [VERSIONS.V3]: redirectKnowMoreCardLinksV2,
        [VERSIONS.V2]: redirectKnowMoreCardLinksV2,
      };
      versionToHandlerMap[productVersion](item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    exploreForexCurrencyRedirection: (
      item: landingForexOfferingCardDataProps
    ) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      const versionToHandlerMap = {
        [VERSIONS.V3]: exploreForexCurrencyRedirectionV2,
        [VERSIONS.V2]: exploreForexCurrencyRedirectionV2,
      };
      versionToHandlerMap[productVersion](item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    dropOffPersuasionRedirection: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      redirectDropOffV3(item, {
        tmBaseUrl,
        otuForexPartnerUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    quickLoadMoneyRedirections: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      const versionToHandlerMap = {
        [VERSIONS.V3]: quickLoadMoneyRedirectionsV2,
        [VERSIONS.V2]: quickLoadMoneyRedirectionsV2,
      };
      item.feature_flag = feature_flag || {};
      versionToHandlerMap[productVersion](item, {
        tmBaseUrl,
        otuForexPartnerUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    completeOrderRedirections: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      const versionToHandlerMap = {
        [VERSIONS.V3]: completeOrderRedirectionsV2,
        [VERSIONS.V2]: completeOrderRedirectionsV2,
      };
      versionToHandlerMap[productVersion](item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    landingPageRedirect: () => {
      closeWebview({ partner });
    },
    trackCardRedirections: (item: landingCardShowcaseDataProps) => {
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      const versionToHandlerMap = {
        [VERSIONS.V3]: trackCardRedirectionsV3,
        [VERSIONS.V2]: trackCardRedirectionsV2,
      };
      versionToHandlerMap[productVersion](item, {
        mwebtmBaseUrl,
        otuForexPartnerUrl,
        partner,
        device,
        tmBaseUrl,
      });
    },
    reloadTripRedirection: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      reloadTripRedirection(item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    viewDashboardRedirections: (item: landingCardShowcaseDataProps) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();
      const productVersion = item?.version || DEFAULT_FLOW;
      const versionToHandlerMap = {
        [VERSIONS.V3]: viewDashboardRedirectionsV2,
        [VERSIONS.V2]: viewDashboardRedirectionsV2,
      };
      item.feature_flag = feature_flag || {};
      versionToHandlerMap[productVersion](item, {
        otuForexPartnerUrl,
        tmBaseUrl,
        mwebtmBaseUrl,
        device,
      });
    },
    viewOrdersRedirections: (
      item: landingCardShowcaseDataProps | { label: string; version: number }
    ) => {
      removeSessionStorageItem(SESSION_STORAGE_KEYS.REQUEST_ID);
      setMmtRedirectionCookie();

      // In listing revamp `view order` will show by default and is not a persuasion
      if (item?.version === VERSIONS.V3) {
        return redirectInterceptHref(
          `${tmBaseUrl}/bmf/currency/post-sales/orders-listing`
        );
      } else {
        const productVersion = item?.version || DEFAULT_FLOW;
        viewOrdersRedirectionsV2(item as landingCardShowcaseDataProps, {
          otuForexPartnerUrl,
          tmBaseUrl,
          device,
          mwebtmBaseUrl,
        });
      }
    },
    crossSellRedirect: ({
      partner,
      crossSellProduct,
    }: {
      partner: string | null | undefined;
      crossSellProduct: string | null | undefined;
    }) => {
      if (!partner || !crossSellProduct) return;
      let redirectionUrl = "";
      persistSpecificParamInCookie(COOKIES.CROSS_SELL_FLOW, crossSellProduct, {
        path: "/",
        domain: partner === MakeMyTrip ? MMT_SUB_DOMAIN : GI_SUB_DOMAIN,
      });
      if (crossSellProduct === CARD_TYPE.MULTI_CURRENCY_CARD) {
        redirectionUrl = `${tmBaseUrl}/bmf/onboarding/traveller-details`;
      } else {
        redirectionUrl = `${tmBaseUrl}/bmf/currency/onboarding/load-currency`;
      }
      redirectInterceptHref(redirectionUrl);
    },
  };
};
export default useRedirectionUrls;

function getHostPartnerInfoForRedirections(partner?: string | null) {
  const source = getSourceName();
  let response = {
    host: source,
    partner,
  };
  switch (source) {
    case SOURCE.TRIPMONEY:
    case SOURCE.LOCALHOST: {
      const host = getPartnerSourceForTM(partner);
      response = { ...response, host };
      break;
    }
    case SOURCE.MAKEMYTRIP:
      response = { ...response, partner: partner ?? PARTNER.MMT };
      break;
    case SOURCE.GOIBIBO:
      response = { ...response, partner: partner ?? PARTNER.GI };
      break;
  }
  return response;
}

function getPartnerSourceForTM(partner: string | null | undefined) {
  if (partner === PARTNER.MMT) {
    return SOURCE.MAKEMYTRIP;
  } else if (partner === PARTNER.GI) {
    return SOURCE.GOIBIBO;
  } else {
    throw new Error(
      `Wrong partner received from tripmoney app [getHostPartnerInfo]`
    );
  }
}

export function redirectToTMLandingPage(partner: string) {
  redirectInterceptHref(
    `https://www.tripmoney.com/partner/${partner}/context/landing`
  );
}
