import {
  generateRequestId,
  getPartner,
  removeEmptyKeys,
  removeNullableField,
} from "..";
import { COOKIES, LOB, LOB_FUNNEL_TYPE, shortenHeader } from "../../constants";
import { getCookie } from "../cookieUtils";
import { getCommonPdtDataV2 } from "./getCommonPdtDataV2";
import {
  IBookingDetails,
  ICampaignDetails,
  IComponentsPdt,
  IDeliveryDetails,
  IDeviceContext,
  IErrorDetailsItem,
  IEventDetailInput,
  IEventTrackingContext,
  IExperimentDetails,
  IPageDetails,
  IPdtDataObjV2,
  ITmUserDetails,
  IUserContext,
  IVendorErrorDetails,
  IEventDetailOutput,
  IPageContextOutput,
  IPageContextInput,
  IBookingInfo,
  IUpcomingTripInput,
  IUpcomingTripOutput,
  ITripDetailsInput,
  ITripDetailsOutput,
} from "./types";

export const setPdtLocationDetectedSchema = (
  locationSource: any,
  permissionDetails: any,
  delivery_details: any
) => {
  const { latitude, longitude, pincode, city } = delivery_details || {};
  return {
    latitude: latitude ? `${latitude}` : null,
    longitude: longitude ? `${longitude}` : null,
    location_source: locationSource || null,
    pincode: pincode ? `${pincode}` : null,
    city: city ? `${delivery_details?.city}` : null,
    location_permission: permissionDetails || null,
  };
};

export function getDeliveryDetailsPdtSchema({
  deliveryDetails,
}: {
  deliveryDetails: any;
}) {
  const { pincode, city, latitude, longitude } = deliveryDetails || {};
  return {
    pincode: pincode ?? null,
    city: city ? `${city}` : "",
    location_permission_given: latitude && longitude ? true : false,
  };
}

export function getVendorErrorDetails({
  error_code,
  error_type,
  error_source,
  vendor_error_code,
  vendor_error_message,
  error_message_shown,
  api_url,
}: IVendorErrorDetails) {
  const vendorErrorObj = {
    error_code: error_code ? `${error_code}` : "NO_CODE_FOUND",
    error_type: error_type || null,
    error_source: error_source || null,
    vendor_error_code: vendor_error_code || null,
    vendor_error_message: vendor_error_message || null,
    error_message_shown: error_message_shown || "Unknown error",
    api_url: api_url || null,
  };
  return removeNullableField(vendorErrorObj);
}

export function getDeliveryDetails({
  eta,
  pincode,
  city,
  is_deliverable,
  is_prefetched,
  location_permission_given,
  deliverable,
  city_code,
  locus_city_code,
  state,
  state_code,
  is_valid,
  is_card_deliverable,
  is_currency_deliverable,
  fallback_to_city,
}: IDeliveryDetails) {
  return {
    eta: eta || "",
    pincode: pincode || 0,
    city: city || "",
    is_deliverable: is_deliverable || "",
    is_prefetched: is_prefetched || false,
    location_permission_given: location_permission_given || false,
    deliverable: deliverable || "",
    city_code: city_code || "",
    locus_city_code: locus_city_code || "",
    state: state || "",
    state_code: state_code || "",
    is_valid: is_valid || false,
    is_card_deliverable: is_card_deliverable || false,
    is_currency_deliverable: is_currency_deliverable || false,
    fallback_to_city: fallback_to_city !== undefined ? fallback_to_city : null,
  };
}

export function getBookingInfo({
  amount_details,
  booking_date,
  booking_id,
  booking_parent_id,
  countries,
  coupon_details,
  currency,
  delivery_city_code,
  delivery_city_name,
  document_upload_status,
  from_date_time,
  is_coupon_applied,
  is_partial_amount_paid,
  is_payment_possible,
  is_tcs_applied,
  order_items,
  order_type_code,
  payment_status,
  status,
  status_description,
  tm_order_id,
  to_date_time,
  trip_purpose,
  trip_type,
}: IBookingInfo): IBookingInfo {
  return {
    amount_details: amount_details || null,
    booking_date: booking_date || null,
    booking_id: booking_id || null,
    booking_parent_id: booking_parent_id || null,
    countries: countries || null,
    coupon_details: coupon_details || null,
    currency: currency || null,
    delivery_city_code: delivery_city_code || null,
    delivery_city_name: delivery_city_name || null,
    document_upload_status: document_upload_status || null,
    from_date_time: from_date_time || null,
    is_coupon_applied: is_coupon_applied || null,
    is_partial_amount_paid: is_partial_amount_paid || null,
    is_payment_possible: is_payment_possible || null,
    is_tcs_applied: is_tcs_applied || null,
    order_items: order_items || null,
    order_type_code: order_type_code || null,
    payment_status: payment_status || null,
    status: status || null,
    status_description: status_description || null,
    tm_order_id: tm_order_id || null,
    to_date_time: to_date_time || null,
    trip_purpose: trip_purpose || null,
    trip_type: trip_type || null,
  };
}

export function getBookingDetails({
  status,
  created_at,
  is_coupon_applied,
  is_partial_amount_paid,
  parent_order_id,
  is_tcs_applied,
  status_description,
  document_upload_status,
  payment_details,
}: IBookingDetails) {
  return {
    status: status || null,
    created_at: created_at || null,
    is_coupon_applied: is_coupon_applied || null,
    is_partial_amount_paid: is_partial_amount_paid || null,
    parent_order_id: parent_order_id || null,
    is_tcs_applied: is_tcs_applied || null,
    status_description: status_description || null,
    document_upload_status: document_upload_status || null,
    payment_details: payment_details || null,
  };
}

export function getTmUserDetails({
  tm_uuid,
  partner,
  vendor_user_id,
  vendor_id,
  multi_cx_card_cust,
  currency_cust,
  travel_card_cust,
}: ITmUserDetails) {
  const userObj = {
    tm_uuid: tm_uuid || null,
    partner: partner || null,
    vendor_user_id: vendor_user_id || null,
    vendor_id: vendor_id || null,
    multi_cx_card_cust: multi_cx_card_cust || null,
    currency_cust: currency_cust || null,
    travel_card_cust: travel_card_cust || null,
  };
  return removeNullableField(userObj);
}

export function getComponentsPdt({
  id,
  content_details,
  component_type,
  share_details,
  bridge_status,
}: IComponentsPdt) {
  const componentObj = {
    id: id || null,
    content_details: content_details || null,
    component_type: component_type || null,
    share_details: share_details || null,
    bridge_status: bridge_status || null,
  };
  return removeNullableField(componentObj);
}
export function getEventDetails({
  page_details,
  delivery_details,
  response_time,
  vendor_error_details,
  event_name,
  event_type = "",
  event_value,
  booking_info,
  components,
  event_id = "",
  event_timestamp,
  user_details,
  webhook_details,
  document_details,
  upcoming_trip,
}: IEventDetailInput): IEventDetailOutput {
  const obj = {
    page_details: page_details || null,
    delivery_details: delivery_details || null,
    response_time: response_time || null,
    vendor_error_details: vendor_error_details || null,
    event_name: event_name || "NA",
    event_type: event_type || "NA",
    event_value: event_value || null,
    booking_info: booking_info || null,
    components: components || null,
    event_id: event_id || null,
    event_timestamp: event_timestamp || new Date().getTime(), // non nullable
    user_details: user_details || null,
    webhook_details: webhook_details || null,
    document_details: document_details || null,
    upcoming_trip: upcoming_trip ? getUpcomingTrip(upcoming_trip) : null,
  };
  return removeNullableField(obj);
}

function getUpcomingTrip({
  intl_travel,
  trip_details,
}: IUpcomingTripInput): IUpcomingTripOutput {
  const upcomingTrip = {
    intl_travel,
    trip_details: getTripDetails(trip_details),
  };
  return removeNullableField(upcomingTrip);
}

function getTripDetails(
  trip_details: ITripDetailsInput[] | undefined
): ITripDetailsOutput[] | null {
  const tripDetails = (trip_details ?? [])
    .map((tripDetail) => {
      return removeNullableField({
        dependent_lob: tripDetail.dependent_lob,
        country_code: tripDetail.country_code,
        currency_code: tripDetail.currency_code,
        start_date: tripDetail.start_date,
        end_date: tripDetail.end_date,
        no_of_travellers: tripDetail.no_of_travellers,
        opportunity_type: tripDetail.opportunity_type,
      });
    })
    .filter((tripDetail) => tripDetail);

  return tripDetails.length ? tripDetails : null;
}

export function getPageDetails({
  segment_key = null,
  documentscanfailed = null,
  secondary_page_name = null,
  auto_suggest = null,
  is_pan_autofilled,
  is_mobile_prefilled,
  Is_email_prefilled,
}: IPageDetails) {
  const pageDetailsObj = {
    segment_key: segment_key || null,
    documentscanfailed: documentscanfailed || null,
    secondary_page_name: secondary_page_name || null,
    auto_suggest: auto_suggest || null,
    is_pan_autofilled: is_pan_autofilled, // mandatory why?
    is_mobile_prefilled: is_mobile_prefilled, // Mandatory why?
    Is_email_prefilled: Is_email_prefilled, // Mandatory why?
  };
  return removeNullableField(pageDetailsObj);
}

export function getPageContext({
  lob,
  lob_category,
  lob_funnel,
  page_name,
  prev_page_name,
  subpage_name,
  funnel_step,
  pg_time_interval,
  navigation,
  page_url,
}: IPageContextInput): IPageContextOutput {
  const pageContextObj = {
    lob: lob || LOB.FOREX,
    lob_category: lob_category || null,
    lob_funnel: lob_funnel || null,
    page_name: page_name || null,
    prev_page_name: prev_page_name || null,
    subpage_name: subpage_name || null,
    funnel_step: funnel_step || null,
    pg_time_interval: pg_time_interval ?? null,
    navigation: navigation || null,
    page_url: page_url || null,
  };
  return removeNullableField(pageContextObj);
}

export function getEventTrackingContext({
  template_id,
  topic_name,
  env,
  session_id,
  request_id,
  journey_id,
  bu,
  organisation,
  funnel_source,
  funnel_entry,
  traffic_source,
  domain_country,
  domain_currency,
  domain_language,
  affiliate_id,
  timestamp,
  funnel_medium,
  funnel_campaign,
  cmp,
}: IEventTrackingContext) {
  const obj = {
    template_id,
    topic_name,
    env,
    session_id: session_id || null,
    request_id: request_id || null,
    journey_id: journey_id || null,
    bu: bu || null,
    organisation: organisation || null,
    funnel_source: funnel_source || null,
    funnel_entry: funnel_entry || null,
    traffic_source: traffic_source || null,
    domain_country: domain_country || null,
    domain_currency: domain_currency || null,
    domain_language: domain_language || null,
    affiliate_id: affiliate_id || null,
    timestamp,
    funnel_medium: funnel_medium || null,
    funnel_campaign: funnel_campaign || null,
    cmp: cmp || null,
  };
  return removeNullableField(obj);
}

export function getErrorDetailsItem({
  code,
  message,
  severity,
  source,
}: IErrorDetailsItem) {
  return {
    code,
    message,
    severity: severity || null,
    source: source || null,
  };
}

export function getUserContext({
  uuid,
  is_logged_in,
  marketing_cloud_id,
  login_channel,
  profile_type,
  wallet_bal,
  login_type,
  loyalty_status,
  loyalty_type,
  loyalty_level,
  user_segment_list,
  login_required,
}: IUserContext) {
  const obj = {
    uuid: uuid || null,
    is_logged_in,
    marketing_cloud_id: marketing_cloud_id || null,
    login_channel: login_channel || null,
    profile_type: profile_type || null,
    wallet_bal: wallet_bal || null,
    login_type: login_type || null,
    loyalty_status: loyalty_status || null,
    loyalty_type: loyalty_type || null,
    loyalty_level: loyalty_level || null,
    user_segment_list: user_segment_list || null,
    login_required: login_required || null,
  };
  return removeNullableField(obj);
}

export function getDeviceContext({
  device_id,
  ip_addr,
  network_type,
  os_version,
  user_agent,
  timezone,
  platform,
  country,
  region,
  city,
  lat,
  long,
  app_ver,
  build_ver,
  os,
  visitor_id,
  advertiser_id,
  vendor_id,
  device_token,
}: IDeviceContext) {
  const obj = {
    device_id: device_id || null,
    ip_addr: ip_addr || null,
    network_type: network_type || null,
    os_version: os_version || "",
    user_agent: user_agent || null,
    timezone: timezone || null,
    platform: platform || "NA",
    country: country || null,
    region: region || null,
    city: city || null,
    lat: lat || null,
    long: long || null,
    app_ver: app_ver || "",
    build_ver: build_ver || null,
    os: os || "",
    visitor_id: visitor_id || null,
    advertiser_id: advertiser_id || null,
    vendor_id: vendor_id || null,
    device_token: device_token || null,
  };
  return removeNullableField(obj);
}

export function getExperimentDetails({
  valid_exp_list,
  variant_keys,
  honoured_exp_list,
}: IExperimentDetails) {
  return {
    valid_exp_list: valid_exp_list || [],
    variant_keys: variant_keys || "",
    honoured_exp_list: honoured_exp_list || [],
  };
}

export function getCampaignDetails({
  campaign_name,
  campaign_key,
}: ICampaignDetails) {
  return {
    campaign_name: campaign_name || "",
    campaign_key: campaign_key || "",
  };
}

export function getPdtDataObjV2({
  page_context,
  event_tracking_context,
  error_details_list,
  user_context,
  device_context,
  experiment_details,
  campaign_details,
  tracking_info,
  event_detail,
  traveller_info,
  addon_details,
  search_context,
}: IPdtDataObjV2) {
  return {
    page_context,
    event_tracking_context,
    error_details_list: error_details_list || null,
    user_context,
    device_context,
    experiment_details: experiment_details || null,
    campaign_details: campaign_details || null,
    tracking_info: tracking_info || null,
    event_detail,
    traveller_info: traveller_info || null,
    addon_details: addon_details || null,
    search_context: search_context || null,
  };
}

export function getPdtHeaders({
  lobCategory,
  lobFunnelType,
}: {
  lobCategory: string;
  lobFunnelType: string;
}) {
  const partner = getPartner();
  let { page_context } = getCommonPdtDataV2({
    partner,
    lobCategory,
    lobFunnelType,
  });
  page_context = removeEmptyKeys(page_context);
  page_context = getShortHeaders(page_context as IPageContextOutput);

  const headers: any = {
    "page-context": page_context,
  };
  const journeyId = getCookie(COOKIES.PDT_JOURNEY_ID, true);
  if (journeyId) {
    headers["x-jo-id"] = journeyId;
  }
  return headers;
}

function getShortHeaders(page_context: IPageContextOutput) {
  const result: any = {};
  for (let [key, value] of Object.entries(page_context || {})) {
    result["x-" + (shortenHeader[key] || key)] = value;
  }
  return result;
}

function setPdtHeaders(pdtHeader: any, headers: any) {
  for (let [headerKey, headerValue] of Object.entries(pdtHeader)) {
    if (typeof headerValue === "object") {
      setPdtHeaders(headerValue, headers);
    } else {
      headers[headerKey] = headerValue as string;
    }
  }
}
export function setPageContextHeaders(headers: any) {
  const pdtHeader = getPdtHeaders({
    lobCategory: "",
    lobFunnelType: LOB_FUNNEL_TYPE.LISTING,
  });
  setPdtHeaders(pdtHeader, headers);
}

export function getRequestIdHeaders(
  reqId?: string | null
): Record<string, string> {
  const headers: Record<string, string> = {};
  headers[`x-rq-id`] = reqId || generateRequestId();
  return headers;
}

export * from "./getCommonPdtDataV2";
export * from "./types";
