import { DEFAULT_MX_FLOW_VERSION } from "../constants";
import {
  ActionTypeOptions,
  BannersData,
  DeliveryDetails,
  ErrorType,
  ForexProducts,
  ForexRates,
  OfferPersuationsType,
  PersonaType,
  ProductData,
  ProductType,
} from "./../types/index";
import {
  ERROR_CARD_CTA_SIGNAL_STATE,
  ERROR_LISTING_PAGE_LOCATION_CHANGE,
  FETCH_CARD_CTA_SIGNAL_STATE,
  FETCH_DELIVERY_DETAILS,
  FETCH_FOREX_CURRENCY_RATE,
  FETCH_LISTING_PAGE_LOCATION_CHANGE,
  IS_ERROR_FETCHING_DELIVERY_DETAILS,
  IS_LOADING_DELIVERY_DETAILS,
  LOADING_CARD_CTA_SIGNAL_STATE,
  LOADING_LISTING_PAGE_LOCATION_CHANGE,
  PERSONA_AND_VERSION_FETCHED_ON_LOAD,
  UPDATE_LOGIN_EMAIL_MODAL_STATE,
  FETCH_CROSS_SELL_DATA,
} from "./actionType";

type ApiErrorType = {
  description?: string;
};

type DeliveryDetailsStateType = {
  isLoadingDeliveryDetails?: boolean;
  delivery_details?: DeliveryDetails | null;
  isErrorDeliveryDetails?: boolean;
  isSuccessDeliveryDetails?: boolean;
  errorMessageFetchingDeliveryDetails?: ApiErrorType;
};

type ListingPageLocationChangeStateType = {
  isLoadingListingPageLocationChange?: boolean;
  isErrorListingPageLocationChange?: boolean;
  errorListingPageLocationChange?: ApiErrorType;
  isSuccessListingDetailsOnLocChange?: boolean;
  persona?: PersonaType | null | undefined;
  products?: ForexProducts;
  forex_rates?: ForexRates | null | undefined;
  version?: number | null | undefined;
  tm_user_id?: number | null | undefined;
  puid?: number | null | undefined;
  downtime?: boolean | null | undefined;
  banners?: BannersData | null | undefined;
  offer_persuasion_text?: string | null;
  offer_persuasions?: OfferPersuationsType[] | null | undefined;
  feature_flag?: { solve_reload?: boolean };
  recommended_currencies_ticker?: RecommendedCurrenciesTicker[];
  experiments?: Experiments;
};

export interface RecommendedCurrenciesTicker {
  currency_source?: string;
  label?: string;
  currency_name?: string;
  currency_code?: string;
  currency_rate?: {
    card?: number;
    currency?: number;
  };
  image_url?: string;
}

type CardCtaSignalStateErrorType = {
  [x in ProductType]?: ErrorType | null;
};
type CardCtaSignalStateType = {
  isLoadingCardCtaSignalState?: boolean;
  isSuccessCardCtaSignalState?: boolean;
  isErrorCardCtaSignalState?: boolean;
  errorListingCardCtaSignalState?: CardCtaSignalStateErrorType;
};

type GetConfigReducerType = {
  isLoadingGetConfigSignalState?: boolean;
  isSuccessGetConfigSignalState?: boolean;
  isErrorGetConfigSignalState?: boolean;
};

type GlobalCommonReducerType = {
  forceLoginEmailModal: boolean;
};

type PiiDataType = {
  pan?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
  userId?: string | null;
  isPanVerified?: boolean | null;
};

type AddressDataType = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  pincode?: string | null;
  cityCode?: string | null;
  stateCode?: string | null;
  addressId?: number | null;
};

type TripDetailType = {
  countryName: string;
  currencyCode: string;
  rate: number;
  countryCode: string;
};

type UpcomingTripsDataType = {
  intlTravel: boolean;
  startDate: string;
  endDate: string;
  noOfTravellers: number;
  tripDetails: TripDetailType[];
};

type CardType = {
  acqCurr: string;
  acqQuantity: number;
  isActive: boolean;
  rate: number;
};

export type CrossSellDataType = {
  crossSellProduct?: string | null;
  crossSellPii?: PiiDataType | null;
  crossSellAddress?: AddressDataType | null;
  crossSellDeliveryDetails?: DeliveryDetails | null;
  crossSellUpcomingTrips?: UpcomingTripsDataType | null;
  crossSellCards?: CardType[] | null;
};

type Experiments = {
  swap_mini_dashboard_btns?: Experiment;
};

type Experiment = {
  enabled: boolean;
  experiment_id: number;
  variant_key: number;
};

export type ListingReducerType = DeliveryDetailsStateType &
  ListingPageLocationChangeStateType &
  CardCtaSignalStateType &
  GetConfigReducerType &
  GlobalCommonReducerType &
  CrossSellDataType &
  Experiments;

const initialVersion = {
  version: DEFAULT_MX_FLOW_VERSION,
};

const initialDeliveryDetails = {
  isLoadingDeliveryDetails: false,
  delivery_details: null,
  isErrorDeliveryDetails: false,
  isSuccessDeliveryDetails: false,
  errorMessageFetchingDeliveryDetails: {
    description: "",
  },
};

const initialListingPageLocationChange = {
  isLoadingListingPageLocationChange: false,
  isErrorListingPageLocationChange: false,
  errorListingPageLocationChange: {
    description: "",
  },
  isSuccessListingDetailsOnLocChange: false,

  // persona: {},
  // products: [],
  // forex_rates: {}
};

const initailCardCtaSignalState: CardCtaSignalStateType = {
  isLoadingCardCtaSignalState: false,
  isSuccessCardCtaSignalState: false,
  isErrorCardCtaSignalState: false,
  errorListingCardCtaSignalState: {
    inr_card: null,
    multi_cx_card: null,
    currency: null,
  },
};

const initialGetConfigState: GetConfigReducerType = {
  isLoadingGetConfigSignalState: false,
  isSuccessGetConfigSignalState: false,
  isErrorGetConfigSignalState: false,
};

const initialGlobalCommonState: GlobalCommonReducerType = {
  forceLoginEmailModal: true,
};

const initialCrossSellData: CrossSellDataType = {
  crossSellProduct: null,
  crossSellPii: null,
  crossSellAddress: null,
  crossSellDeliveryDetails: null,
  crossSellUpcomingTrips: null,
  crossSellCards: null,
};

export const intialListingData: ListingReducerType = {
  ...initialVersion,
  ...initialDeliveryDetails,
  ...initialListingPageLocationChange,
  ...initailCardCtaSignalState,
  ...initialGlobalCommonState,
  ...initialCrossSellData,
};

export const listingReducer = (
  state: ListingReducerType,
  {
    type,
    payload,
    options,
  }: { type: string; payload?: any; options?: ActionTypeOptions }
): ListingReducerType => {
  const { card_type } = options || {};
  switch (type) {
    // Only for currency rates.
    case FETCH_FOREX_CURRENCY_RATE:
      return { ...state, forex_rates: { ...payload } };

    // DELIVERY DETAILS
    case FETCH_DELIVERY_DETAILS:
      return {
        ...state,
        ...initialDeliveryDetails,
        isSuccessDeliveryDetails: true,
        delivery_details: { ...payload },
      };
    case PERSONA_AND_VERSION_FETCHED_ON_LOAD:
      const { persona, version } = payload;
      return {
        ...state,
        persona,
        version,
      };
    case IS_LOADING_DELIVERY_DETAILS:
      return {
        ...state,
        ...initialDeliveryDetails,
        isLoadingDeliveryDetails: true,
      };
    case IS_ERROR_FETCHING_DELIVERY_DETAILS:
      return {
        ...state,
        ...initialDeliveryDetails,
        isErrorDeliveryDetails: true,
        errorMessageFetchingDeliveryDetails: { ...payload },
      };

    // Listing page: "forex rates", "persona", "products"
    case LOADING_LISTING_PAGE_LOCATION_CHANGE:
      return {
        ...state,
        ...initialListingPageLocationChange,
        isLoadingListingPageLocationChange: true,
      };
    case FETCH_LISTING_PAGE_LOCATION_CHANGE:
      return {
        ...state,
        ...initialListingPageLocationChange,
        isSuccessListingDetailsOnLocChange: true,
        ...payload,
      };

    case FETCH_CROSS_SELL_DATA:
      return {
        ...state,
        ...initialCrossSellData,
        ...payload,
      };

    case ERROR_LISTING_PAGE_LOCATION_CHANGE:
      return {
        ...state,
        ...initialListingPageLocationChange,
        isErrorListingPageLocationChange: true,
        errorListingPageLocationChange: { ...payload },
      };

    // Card cta signal state.
    case LOADING_CARD_CTA_SIGNAL_STATE:
      return {
        ...state,
        ...initailCardCtaSignalState,
        isLoadingCardCtaSignalState: true,
      };

    case FETCH_CARD_CTA_SIGNAL_STATE:
      return {
        ...state,
        ...initailCardCtaSignalState,
        isSuccessCardCtaSignalState: true,
        ...updateProductsWithCtaState(state?.products, card_type, payload),
      };

    case ERROR_CARD_CTA_SIGNAL_STATE:
      return {
        ...state,
        ...initailCardCtaSignalState,
        isErrorCardCtaSignalState: true,
        errorListingCardCtaSignalState: {
          ...initailCardCtaSignalState.errorListingCardCtaSignalState,
          [card_type as string]: { ...payload },
        },
      };

    // Update global common state.

    case UPDATE_LOGIN_EMAIL_MODAL_STATE: {
      return {
        ...state,
        ...initialGlobalCommonState,
        forceLoginEmailModal: payload,
      };
    }

    default:
      throw new Error("Invalid action in [Listing Reducer]");
  }
};

function updateProductsWithCtaState(
  products: any,
  cardType: ProductType | undefined,
  payload: any
) {
  if (!cardType) return products || [];
  for (const product of products) {
    for (let [card, cardValue] of Object.entries<ProductData>(product)) {
      if (card === cardType) {
        cardValue = { ...cardValue, ...payload };
      }
      product[card] = cardValue;
    }
  }
  return products;
}
